<template>
  <div class="text-left">
    <!-- <Heading heading="Account"  /> -->
    <div class="" v-if="!isLoadingFirstTime || getIsAccountDataLoading">
      <div class="account-detail">
        <form>
          <b-row class="mb-3">
            <b-col cols="12" md="4">
              <div class="v16-section-heading">Username</div>
            </b-col>
            <b-col cols="12" md="7">
              <div class="v-16-input-control">
                <input type="text" v-model="user.name" name="" id="" />
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="12" md="4">
              <div class="v16-section-heading">Email</div>
            </b-col>
            <b-col cols="12" md="7">
              <div class="v-16-input-control" style="background: #fafafa">
                <div class="">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.3334 5.00004C18.3334 4.08337 17.5834 3.33337 16.6667 3.33337H3.33335C2.41669 3.33337 1.66669 4.08337 1.66669 5.00004M18.3334 5.00004V15C18.3334 15.9167 17.5834 16.6667 16.6667 16.6667H3.33335C2.41669 16.6667 1.66669 15.9167 1.66669 15V5.00004M18.3334 5.00004L10 10.8334L1.66669 5.00004"
                      stroke="#667085"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <input
                  v-model="user.email"
                  disabled
                  type="email"
                  class="pl-2"
                  name="user-email"
                  id=""
                />
                <div class="d-flex gap align-items-center">
                  <svg
                    v-if="isEmailVerified"
                    style="cursor: pointer"
                    v-b-tooltip.hover
                    title="Verified email"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_250_1977)">
                      <path
                        d="M14.6673 7.38625V7.99958C14.6665 9.4372 14.201 10.836 13.3402 11.9875C12.4794 13.1389 11.2695 13.9812 9.89089 14.3889C8.51227 14.7965 7.03882 14.7475 5.6903 14.2493C4.34177 13.7511 3.19042 12.8303 2.40796 11.6243C1.6255 10.4183 1.25385 8.99163 1.34844 7.55713C1.44303 6.12263 1.99879 4.75714 2.93284 3.6643C3.86689 2.57146 5.12917 1.80984 6.53144 1.49301C7.93371 1.17619 9.40083 1.32114 10.714 1.90625M14.6673 2.66625L8.00066 9.33958L6.00065 7.33958"
                        stroke="#64BC26"
                        stroke-width="1.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_250_1977">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    @click="$refs.verifyEmail.OpenModal()"
                    style="cursor: pointer"
                    v-b-tooltip.hover
                    title="Change email"
                    v-if="!user.is_invited"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_541_289)">
                      <path
                        d="M9.16663 3.3332H3.33329C2.89127 3.3332 2.46734 3.50879 2.15478 3.82135C1.84222 4.13391 1.66663 4.55784 1.66663 4.99986V16.6665C1.66663 17.1086 1.84222 17.5325 2.15478 17.845C2.46734 18.1576 2.89127 18.3332 3.33329 18.3332H15C15.442 18.3332 15.8659 18.1576 16.1785 17.845C16.491 17.5325 16.6666 17.1086 16.6666 16.6665V10.8332M15.4166 2.0832C15.7481 1.75168 16.1978 1.56543 16.6666 1.56543C17.1355 1.56543 17.5851 1.75168 17.9166 2.0832C18.2481 2.41472 18.4344 2.86436 18.4344 3.3332C18.4344 3.80204 18.2481 4.25168 17.9166 4.5832L9.99996 12.4999L6.66663 13.3332L7.49996 9.99986L15.4166 2.0832Z"
                        stroke="#667085"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_541_289">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col cols="12" md="4">
              <div class="v16-section-heading">Phone number</div>
            </b-col>
            <b-col cols="12" md="7">
              <div class="v-16-input-control" style="padding: 2px 0px">
                <VueTelInput
                  class="tel-number"
                  :inputOptions="phoneInputOption"
                  :dropdownOptions="dropdown"
                  @validate="onValidation"
                  v-model="user.phone"
                  mode="international"
                  :validCharactersOnly="true"
                />
              </div>
            </b-col>
          </b-row>
        </form>
      </div>
      <div class="account-detail">
        <b-row class="mb-3">
          <b-col cols="12" md="4">
            <div class="v16-section-heading">Your photo</div>
            <div class="v16-section-description">
              This will be displayed on your profile.
            </div>
          </b-col>
          <b-col cols="12" md="7">
            <div class="d-flex align-items-center" style="gap: 24px">
              <uploadImage
                ref="uploadImage"
                :isLoading="isImageUpdating"
                @change="changeImage"
                :uploadImage="user.image"
                :isUpload="true"
                :text="user.name"
              />
              <div
                class="d-flex"
                v-if="imageFile"
                :style="{
                  'pointer-event': isImageUpdating ? 'none' : 'all',
                  opacity: isImageUpdating ? 0.8 : 1,
                }"
                style="
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  gap: 16px;
                "
              >
                <div
                  class="cursor-pointer"
                  @click="$refs.uploadImage.deleteImagePreview()"
                  style="color: var(--delete-color)"
                >
                  Delete
                </div>
                <div
                  class="cursor-pointer"
                  @click="uploadOnServer()"
                  style="color: var(--primary-color)"
                >
                  Update
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="account-detail">
        <b-row class="mb-3">
          <b-col cols="12" md="4">
            <div class="v16-section-heading">Current password</div>
          </b-col>
          <b-col cols="12" md="7">
            <div class="v-16-input-control">
              <input
                type="password"
                name="user-password"
                value="*******"
                id=""
              />
            </div>
            <div class="mt-2">
              <div
                class="d-flex gap align-items-center change-btn"
                @click="$refs.ChangePassword.OpenModal()"
              >
                <div class="">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.1667 3.33331V8.33331M19.1667 8.33331H14.1667M19.1667 8.33331L15.3 4.69998C14.4044 3.80391 13.2964 3.14932 12.0794 2.79729C10.8623 2.44527 9.57596 2.40727 8.34028 2.68686C7.10459 2.96645 5.95987 3.55451 5.01293 4.39616C4.06598 5.23782 3.34768 6.30564 2.92504 7.49998M0.833374 16.6666V11.6666M0.833374 11.6666H5.83337M0.833374 11.6666L4.70004 15.3C5.59566 16.1961 6.70368 16.8506 7.92071 17.2027C9.13774 17.5547 10.4241 17.5927 11.6598 17.3131C12.8955 17.0335 14.0402 16.4455 14.9872 15.6038C15.9341 14.7621 16.6524 13.6943 17.075 12.5"
                      stroke="#0397CC"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="secondary-effect">Change Password</div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="">
        <EditProductFooter
          @save="submitUserDetail"
          @discard="user = { ...discardedData }"
          :isLoadingSave="isSubmit"
          :isDelete="false"
        ></EditProductFooter>
      </div>

      <div>
        <div class="">
          <ChangePassword ref="ChangePassword" :user="user"></ChangePassword>
          <VerifyEmail ref="verifyEmail" :user="user"></VerifyEmail>
        </div>
      </div>
    </div>
    <AccountSkeleton v-else></AccountSkeleton>
  </div>
</template>
<script>
import AccountSkeleton from "./components/AccountSkeleton.vue";
import EditProductFooter from "../Layout/EditProductFooter.vue";
import { mapGetters, mapActions } from "vuex";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import UploadImage from "./components/UploadImage.vue";
import axios from "axios";
import globalMixin from "../../../GlobalMixins/getSignedURLMixin";
import ChangePassword from "./components/ChangePassword.vue";
import VerifyEmail from "./components/VerifyEmail.vue";
export default {
  components: {
    VueTelInput,
    UploadImage,
    EditProductFooter,
    ChangePassword,
    VerifyEmail,
    AccountSkeleton,
  },

  mixins: [globalMixin],

  data() {
    return {
      isImageUpdating: false,
      imageFile: null,
      phoneValid: false,
      phoneInputOption: {
        placeholder: "Enter Phone Number",
        maxlength: 15,
      },
      discardedData: null,
      dropdown: {
        showSearchBox: true,
        showFlags: true,
        width: "390px",
      },
      isLoadingFirstTime: true,
      isAccountLoading: false,
      isSubmit: false,
    };
  },
  computed: {
    isEmailVerified() {
      if (this.getUserAllAccountData?.account) {
        let { email_verified_at } = this.getUserAllAccountData.account;
        return typeof email_verified_at == "string" ? true : false;
      }
      return false;
    },
    ...mapGetters([
      "GetTokenFromMetaTag",
      "getUserAllAccountData",
      "getIsAccountDataLoading",
      "getUserAllAccountData",
    ]),
    userAllAccountData() {
      return this.getUserAllAccountData;
    },
    Limitations(){
      return this.userAllAccountData?.limitations || []
    },
    plan(){
      return this.userAllAccountData?.plan || {}
    },
    user(){
      return this.userAllAccountData?.account || {}
    },
  },
  async created() {
    await this.getUserData();
  },
  methods: {
    changeImage(image) {
      this.imageFile = image;
    },
    uploadOnServer() {
      let file = this.imageFile;
      this.isImageUpdating = true;
      let validImageTypes = [
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/svg+xml",
      ];
      try {
        let fileType = file.type;
        let fileName = file.name;
        if (validImageTypes.includes(fileType)) {
          let reader = new FileReader();
          reader.onload = async (e) => {
            let obj = {
              fileName: fileName,
              fileType: fileType,
              foderName: "profiles",
            };

            let getSignedURLResponse = await this.getSignedUrl(obj);
            if (getSignedURLResponse.data.ok) {
              let data = getSignedURLResponse.data;
              let origionalName = data.origionalName;
              let filePath = data.filePath;
              let postData = {
                origionalName: origionalName,
                image_path: filePath,
              };
              let digitalSpacesResponse = await this.UploadSignedURLImage(
                data,
                e
              );
              if (digitalSpacesResponse.ok) {
                var elements = digitalSpacesResponse.url.split("?");
                var fistElement = elements[0];
                var filePortion = fistElement.split("/").pop();
                if (postData.image_path.includes(filePortion)) {
                  try {
                    //  let data = JSON.stringify(element)
                    let data = postData;
                    const finalResponse = await axios.post(
                      `${process.env.VUE_APP_API_BASE_URL}storeProfileImage`,
                      data
                    );
                    if (finalResponse.status == 200) {
                      this.$toasted.show("Image Updated Successfully", {
                        theme: "toasted-primary",
                        position: "bottom-center",
                        duration: 2000,
                      });
                      this.isImageUpdating = false;
                    }
                  } catch (error) {
                    if (error) {
                      this.$toasted.show("Error occured", {
                        theme: "toasted-primary",
                        position: "bottom-center",
                        duration: 2000,
                      });
                      this.isImageUpdating = false;
                      throw error;
                    }
                  }
                }
              }
            }
          };

          // Read in the image file as a Blob.
          reader.readAsArrayBuffer(file);
        }
      } catch (error) {
        this.$refs.uploadImage.deleteImagePreview();
        this.$toasted.show("Error occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        this.isUserUpdating = false;
        throw error;
      }
    },
    async submitUserDetail() {
      this.isSubmit = true;
      try {
        let data = {
          name: this.user.name,
          phone: this.user.phone,
        };
        const response = await axios.post(`/updateUserProfile`, data);
        if (response.status == 200) {
          this.updateAllUserInfo();
          this.$toasted.show("Account has been updated successfully.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(error.response.data.message, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isSubmit = false;
      }
    },
    onValidation(e) {
      if (e.valid !== undefined) {
        this.phoneValid = !e.valid;
      } else {
        this.phoneValid = false;
      }
    },
    checkLimitations(limitations) {
      if (limitations.text == "Questions" || limitations.text == "Products") {
        return limitations.planValue == 0 ? false : true;
      } else {
        return true;
      }
    },
    ...mapActions(["loadNavbarData", "loadAccountDataVuex"]),
    ImageSrc(img) {
      if (img == "uploads/profile/1841662374867.jpg") {
        return "https://cdn.shopify.com/s/files/1/0554/9676/9614/files/default.png?v=1663245344";
      } else {
        return img.startsWith("upload") ? `/${img}` : img;
      }
    },
    async updateAllUserInfo() {
      this.loadAccountDataVuex();
      await this.loadNavbarData();
      this.getUserData();
    },
    async getUserData() {
      this.isLoadingFirstTime = true;
      this.isAccountLoading = true;

      try {
        const response = await this.$store.dispatch("fetchData", {
          axiosMethod: "get",
          resource: "userData",
          method: "info",
        });
        if (response && response.status) {
          this.user = response.data;

          this.discardedData = { ...response.data };
        }
      } catch (error) {
        throw new Error("Error Occured");
      } finally {
        this.isAccountLoading = false;
        this.isLoadingFirstTime = false;
      }
    },
  },
};
</script>

<style scoped>
.change-btn:hover {
  text-decoration: underline;
  color: var(--secondary-color);
  cursor: pointer;
}
.secondary-effect {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--secondary-color);
}
.UserImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.changeImage {
  display: none;
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.ImageDiv:hover .changeImage {
  display: block;
}
.modal-content {
  border-radius: 20px;
  overflow: hidden;
}
.modal-btn {
  background: #ffa201;
  color: #ffffff;
  border: none;
}
.modal-btn:hover {
  color: #ffffff;
}

.btn-profile-seeting {
  border: 1px solid #ffa201;
  color: #ffa201;
}
.btn-profile-seeting:hover {
  border: 1px solid #ffa201;
  color: #ffffff;
  background: #ffa201;
}

.bg-yello-theme {
  background-color: #ffa201 !important;
}
.Limitations >>> .progress {
  background-color: #f4f3f5;
  height: 5px;
  border-radius: 1.25rem;
}

.UserName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 21px;

  color: #292929;
}

.UserValue {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #6d6b6d;
}
.user-input {
  min-width: 25%;
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #ffa201;
}

.plan-name {
  font-family: "Poppins";
  color: #292929;
  font-weight: 700;
  font-size: 30px;
}
.plan-amount {
  font-family: "Poppins";
  color: #292929;
  font-size: 18px;
  font-weight: 300;
}

.plan-amount .price {
  font-size: 38px;
  font-weight: 300;
}
.payment-notice {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #292929;
}
.tel-number {
  flex: 1;
  border: none;
}
.tel-number:focus-within {
  outline: none;
  border: none;
  box-shadow: none;
}
</style>
